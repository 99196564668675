<template>
  <v-container class="pa-0" fluid>
    <page-title
        :heading="$t('erp.lang_garnishGroups')"
        :icon="icon"
        :subheading="subheader"
        show-previous-button
    ></page-title>
    <div class="app-main__inner">
      <EditGarnishGroupComponent v-model="subheader"/>
    </div>
  </v-container>
</template>

<script>
import PageTitle from "@/Layout/Components/PageTitle";
import EditGarnishGroupComponent from "@/components/erp/baseData/garnishgroup/EditGarnishGroupComponent";

export default {
  name: "EditGarnishGroup",
  components: {EditGarnishGroupComponent, PageTitle},
  data() {
    return {
      subheader: "",
      icon: 'mdi mdi-mushroom-outline icon-gradient bg-tempting-azure',
    }
  }
}
</script>

<style scoped>

</style>
